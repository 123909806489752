import {html, css, LitElement} from 'lit';
import { add } from 'lodash';
import SlimSelect from 'slim-select'

export default class MultiSelectInput extends LitElement {
  
  static properties = {
    name: { type: String },
    value: { type: Array },
    options: { type: Array },
    lastSelectedValue: { type: String },
    data: { type: Object },
    disabled: { type: Boolean, default: false }
  }

  // disable shadow dom to use global styles 
  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.name = this.getAttribute("name");
    this.value = JSON.parse(this.getAttribute("value")) || [];
    this.options = JSON.parse(this.getAttribute("options")) || [];
    this.type = this.getAttribute("type");
    this.data = this.getAttribute("data");
    this.disabled = this.getAttribute("disabled");
  }

  connectedCallback() {
    super.connectedCallback()
  }

  async updated() {
    await new Promise((r) => setTimeout(r, 0)); // Give the browser a chance to paint

    if (this.select) {
      this.select.destroy();
    }

    this.select = new SlimSelect({
      select: `#select`,
      settings: {
        showSearch: true,
        hideSelected: true,
      },
      events: {
        afterChange: this.addOption.bind(this)
      },
      data: [{'placeholder': true, 'text': `Add ${this.value.length > 0 ? 'Another' : ''} ${this.type}`}].concat(
        this.availableOptions()
      )
    })
  }

  addOption(option) {
    const newValue = option[0].value
    if (newValue > 0) {
      this.value = [...this.value, newValue]
      this.select.setSelected('', false)
    }
    setTimeout(() => this.dispatchEvent(new Event("change")), 0);
  }

  removeOption(id) {
    this.value = this.value.filter(value => value != id)
    setTimeout(() => this.dispatchEvent(new Event("change")), 0);
  }

  selected() {
    const flattenedOptions = this.options.reduce((acc, item) => {
      if (item.options) {
        // If the item has an 'options' property, concatenate its options
        return acc.concat(item.options);
      } else {
        // Otherwise, add the item directly to the accumulator
        return acc.concat(item);
      }
    }, []);

    return this.value.map(value => {
      return flattenedOptions.find(option => option.value == value)
    })
  }

  availableOptions() {
    const options = this.options.map(item => {
      if (item.options) {
        // If the item has an 'options' property, filter its options
        return {
          ...item,
          options: item.options.filter(option => !this.value.includes(option.value))
        };
      } else {
        // Otherwise, filter the item directly
        return this.value.includes(item.value) ? null : item;
      }
    }).filter(item => item !== null);

    return options
    // return this.options.filter(option => !this.value.includes(option.value))
  }

  render() {
    return html`
      <div class="multi-select-input">
        <input type="hidden" name="${this.name}[]" value="" />
        ${this.value.map(value => html`<input type="hidden" name="${this.name}[]" value="${value}" />`)}
        <div class="selected-options">
          ${this.selected().map(option => html`
            <div class="selected-option">
              <div class="name">${option && (option.name || option.text)}</div> 
              <div class="right">
                <div class="code">${option && option.code}</div> 
                ${!this.disabled ? html`
                  <svg class="delete" @click="${e => this.removeOption(option && option.value)}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.41663 9.5C5.14048 9.5 4.91663 9.72386 4.91663 10C4.91663 10.2761 5.14048 10.5 5.41663 10.5H14.5833C14.8594 10.5 15.0833 10.2761 15.0833 10C15.0833 9.72386 14.8594 9.5 14.5833 9.5H5.41663Z" fill="#859CA4"/>
                  </svg>
                ` : ''}
              </div>
            </div>
          `)}
        </div>
        ${!this.disabled ? html`<select id="select" class="ss-full-size"></select>` : ''}
      </div>
    `;
  }
}

customElements.define('multi-select-input', MultiSelectInput);