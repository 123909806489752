import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = {
    showSearch: Boolean,
    closeOnSelect: {type: Boolean, default: true },
    placeholder: {type: String, default: 'Not Selected'},
    keepPlaceholder: {type: Boolean, default: false },
    options: Array,
    selected: Number,
  }

  

  connect() {    
    this.initializing = true;

    this.element.addEventListener("change", (event) => {
      if (this.initializing) {
        event.stopImmediatePropagation();
      }
    })

    // This is needed when turbo broadcasts / refreshes updates the page and
    // the select element is removed from the page
    document.addEventListener("turbo:render", this.init.bind(this))

    this.init()
  }

  init() {
    this.initializing = true;
    this.destroy();

    if (!this.keepPlaceholderValue) {
      Array.from(this.element.options).forEach((option) => {
        if (option.value === '') {
          option.setAttribute('data-placeholder', 'true');
        }
      });
    }
    
    this.slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: this.placeholderValue,
        showSearch: this.showSearchValue,
        maxValuesShown: 1, // Default 20
        closeOnSelect: this.closeOnSelectValue,
        allowDeselect: this.element.multiple,
        // maxValuesMessage: '{number} values selected', // Default '{number} selected'
        // searchText: 'Sorry nothing to see here',
        // searchPlaceholder: 'Search for the good stuff!',
        // searchHighlight: true        
        // contentPosition: 'relative', // 'absolute' or 'relative'
        // contentLocation: this.element.parentElement
      },    
      events: {
        afterChange: (newVal) => {
          document.dispatchEvent(new Event('slim_select:after_change'));
        }

      },
      data: this.options(),
    })

    this.initializing = false;
  }

  options() {
    let result = this.optionsValue && this.optionsValue.length > 0 ? [
      {
        text: this.placeholderValue, 
        value: "", 
        placeholder: true,
      }
    ].concat(this.optionsValue) : null

    return result
  }

  disconnect() {
    document.removeEventListener("turbo:render", this.init)
    this.destroy()
  }

  destroy() {
    if (!this.slimSelect) return

    this.slimSelect.destroy()
    this.slimSelect = null
  }

}

