import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = ["button", "select"]
  static values = {
    showSearch: Boolean,
    closeOnSelect: {type: Boolean, default: true },
  }

  connect() {    
    document.addEventListener("turbo:render", this.initialize.bind(this))
    this.initialize()
  }

  disconnect() {
    this.destroy()
    document.removeEventListener("turbo:render", this.initialize)
  }

  initialize () {
    this.destroy();

    if (!this.keepPlaceholderValue) {
      Array.from(this.selectTarget.options).forEach((option) => {
        if (option.value === '') {
          option.setAttribute('data-placeholder', 'true');
        }
      });
    }

    this.slimSelect = new SlimSelect({
      select: this.selectTarget,
      settings: {
        placeholderText: 'Select a value',
        showSearch: this.showSearchValue,
        maxValuesShown: 1, // Default 20
        closeOnSelect: this.closeOnSelectValue,
        allowDeselect: this.selectTarget.multiple,
        // maxValuesMessage: '{number} values selected', // Default '{number} selected'
        // searchText: 'Sorry nothing to see here',
        // searchPlaceholder: 'Search for the good stuff!',
        // searchHighlight: true        
        // contentPosition: 'relative', // 'absolute' or 'relative'
        // contentLocation: this.selectTarget.parentElement
      },    
    })
  }

  destroy() {
    if (!this.slimSelect) return

    this.slimSelect.destroy()
    this.slimSelect = null
  }

  open(e) {
    e.preventDefault();
    this.slimSelect.open()
  }

}

