import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Monitor for changes to the list
    this.observer = new MutationObserver(this.handleMutation.bind(this))
    this.observer.observe(this.element, { childList: true, subtree: true })
    this.height = this.element.offsetHeight
  }

  disconnect() {
    this.observer.disconnect()
  }

  handleMutation(mutations) {
    mutations.forEach(mutation => {
      if (mutation.addedNodes.length) {
        // Set explicit height
        this.element.style.height = `${this.height}px`
        
        // Force browser reflow
        this.element.offsetHeight
        
        // Update height to new auto height
        this.element.style.height = 'auto'
        const newHeight = this.element.offsetHeight

        this.element.style.height = `${this.height}px`
        
        // Force browser reflow
        this.element.offsetHeight
        
        // Animate to new height
        this.element.style.height = `${newHeight}px`
        
        // Clean up after animation
        setTimeout(() => {

          this.element.style.height = 'auto'
          this.height = newHeight
        }, 400) // Match this with your CSS transition duration
      }
      else if (mutation.removedNodes.length) {
        setTimeout(() => {
          this.height = this.element.offsetHeight
        }, 400)
      }
    })
  }
} 